import Cookies from 'universal-cookie';
import { add } from 'date-fns';

export class CookieUtil {

    private cookies: Cookies = new Cookies();

    get(k: string): string {
        return this.cookies.get(k);
    }

    set(k: string, v: string) {
        this.cookies.remove(k);
        this.cookies.set(k, v, {
            expires: add(new Date(), {
                days: 3
            })
        })
    }

    remove(k: string) {
        this.cookies.remove(k);
    }

}

export const cookieService = new CookieUtil();