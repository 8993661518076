export type FormType = "form" | "ppp" | "bank_connect" | "";

export class ClientAppConfig {
    companyName: string = '';
    repURL: string = '';
    repName: string = '';
    repPhone: string = '';
    repEmail: string = '';
    repTitle: string = 'Funding Specialist';
    features: {
        bankConnect: boolean
    } = {
            bankConnect: false
        };
    formType: FormType = '';
    esignConditions: string[] = [];
    permissionToContactTerms: string[] = [];
    customScript: string = '';
}

export const API_BASE = process.env['REACT_APP_FORM-API_ENDPOINT'];
export const REPCONFIG_BUCKET_BASE: string = process.env['REACT_APP_REPFORM_CONFIG'] || '';