import React from "react";
import { Typography, Grid, Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress } from "@material-ui/core";
import { InputData } from "../../../util/ui/form/InputData";
import { AbstractInfo } from "./AbstractInfo";
import { FindBusinessResult } from "./business/FindBusinessResult";
import { GuessConfirmed } from "./GuessConfirmedEnum";
import { FormInputPropertiesInterface } from "../../../util/ui/form/FormInputProperties";
import BasicInput from "../../../util/ui/form/inputs/BasicInput";
import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import validator from "validator";
import httpClient, { HttpClientResponse } from "../../../util/HttpClient";


import OtherSelect from "../../../util/ui/form/OtherSelect";
import { entityTypeOptionsMerchantForm, industryTypeOptions, positionOptions, propertyOwnershipOptions, stateKV, useOfProceedsOptions } from "../../../util/SelectOptions";

import BasicSelect from "../../../util/ui/form/BasicSelect";
import CopyRecommendedInput from "../../../util/ui/form/inputs/CopyRecommendedInput";
import SmartyAddressInput from "../../../util/ui/form/inputs/SmartyAddressInput";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { FORMAPI_BASE } from "../BaseUtil";
import CurrencyInput from "../../../util/ui/form/inputs/CurrencyInput";
import PhoneInput from "../../../util/ui/form/inputs/PhoneInput";
import CopyRecommendedInputTaxId from "../../../util/ui/form/inputs/CopyRecommendedInputTaxId";
import YearMonthPicker from "../../../util/ui/form/inputs/YearMonthPicker";
import StringUtils from "../../../util/StringUtils";
import { isValid, parse } from "date-fns";
import { enUS } from "date-fns/locale";



export enum BusinessInputFormNames {
    entityName = "entityName",
    entityDba = "entityDba",
    taxId = "taxId",
    startDate = "startDate",
    entityType = "entityType",
    industryType = "industryType",
    ownLeaseProperty = "ownLeaseProperty",
    zip = "zip",
    address = "address",
    phone = "phone",
    city = "city",
    state = "state",
    amountRequested = "amountRequested",
    useOfProceeds = "useOfProceeds",
    avgMonthlyRevenue = "avgMonthlyRevenue",
    openMcaBalance = "openMcaBalance",
    avgDailyBankBalance = "avgDailyBankBalance",
    stateOfIncorporation = "stateOfIncorporation",
    website = "website",
    currentMCABalance = "currentMCABalance"
}

export const FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {
    entityName: {
        name: BusinessInputFormNames.entityName,
        label: 'Business Legal Name',
        autofill: BusinessInputFormNames.entityName,
        placeholder: 'Legal Name',
        required: true,
        type: 'text'
    },
    entityDba: {
        name: BusinessInputFormNames.entityDba,
        label: 'Business D/B/A Name',
        autofill: BusinessInputFormNames.entityDba,
        placeholder: '',
        required: true,
        type: 'text'
    },
    taxId: {
        name: BusinessInputFormNames.taxId,
        label: 'Tax ID',
        autofill: BusinessInputFormNames.taxId,
        placeholder: '27-1115599',
        required: true,
        type: 'phone',
        inputMode: 'numeric'
    },
    startDate: {
        name: BusinessInputFormNames.startDate,
        label: 'Business Start',
        autofill: BusinessInputFormNames.startDate,
        placeholder: 'mm/yyyy',
        required: true,
        type: 'text',
        inputMode: 'numeric'
    },
    entityType: {
        name: BusinessInputFormNames.entityType,
        label: 'Incorporation Type',
        autofill: BusinessInputFormNames.entityType,
        placeholder: '',
        required: true,
        type: 'text'
    },
    stateOfIncorporation: {
        name: BusinessInputFormNames.stateOfIncorporation,
        label: 'State of Incorporation',
        autofill: BusinessInputFormNames.stateOfIncorporation,
        placeholder: '',
        required: true,
        type: 'text'
    },
    industryType: {
        name: BusinessInputFormNames.industryType,
        label: 'Type of Business',
        autofill: BusinessInputFormNames.industryType,
        placeholder: '',
        required: true,
        type: 'text'
    },
    ownLeaseProperty: {
        name: BusinessInputFormNames.ownLeaseProperty,
        label: 'Own/Lease Property',
        autofill: BusinessInputFormNames.ownLeaseProperty,
        placeholder: '',
        required: true,
        type: 'custom'
    },
    address: {
        name: BusinessInputFormNames.address,
        label: 'Business Address',
        autofill: BusinessInputFormNames.address,
        placeholder: '',
        required: true,
        type: 'text'
    },
    zip: {
        name: BusinessInputFormNames.zip,
        label: 'Business Zip Code',
        autofill: BusinessInputFormNames.zip,
        placeholder: '10001',
        required: true,
        type: 'phone',
        inputMode: 'numeric',
        maxLength: 5
    },
    phone: {
        name: BusinessInputFormNames.phone,
        label: 'Contact Number',
        autofill: BusinessInputFormNames.phone,
        placeholder: '(999) 999-9999',
        required: true,
        type: 'phone',
        inputMode: 'numeric'
    },
    amountRequested: {
        name: BusinessInputFormNames.amountRequested,
        label: 'Amount Requested',
        autofill: BusinessInputFormNames.amountRequested,
        placeholder: 'Amount Requested',
        required: true,
        type: 'number',
        inputMode: 'numeric'
    },
    useOfProceeds: {
        name: BusinessInputFormNames.useOfProceeds,
        label: 'Use of Funds',
        autofill: BusinessInputFormNames.useOfProceeds,
        placeholder: 'Use of Funds',
        required: true,
        type: 'text'
    },
    avgMonthlyRevenue: {
        name: BusinessInputFormNames.avgMonthlyRevenue,
        label: 'Average Monthly Revenue',
        autofill: BusinessInputFormNames.avgMonthlyRevenue,
        placeholder: 'Avg Monthly Revenue',
        required: true,
        type: 'number',
        inputMode: 'numeric'
    },
    openMcaBalance: {
        name: BusinessInputFormNames.openMcaBalance,
        label: 'Open MCA/Loan Balance',
        autofill: BusinessInputFormNames.openMcaBalance,
        placeholder: 'Open MCA/Loan Balance - Leave empty if none',
        required: false,
        type: 'number',
        inputMode: 'numeric'
    },
    avgDailyBankBalance: {
        name: BusinessInputFormNames.avgDailyBankBalance,
        label: 'Average Daily Bank Balance',
        autofill: BusinessInputFormNames.avgDailyBankBalance,
        placeholder: 'Approx. Avg Daily Bank Balance',
        required: false,
        type: 'number',
        inputMode: 'numeric'
    },
    website: {
        name: BusinessInputFormNames.website,
        label: 'Website',
        autofill: BusinessInputFormNames.website,
        placeholder: 'www.yourbusiness.com',
        required: false,
        type: 'text'
    },
};

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});


export class BusinessInfo extends AbstractInfo {

}

interface BusinessInfoFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onGoBack(): void;
    formToken: string;
}

export class BusinessInfoFirstGuess {
    performed: boolean = false;
    found: boolean = false;
    confirmed: number = 0;
    resultInfo: FindBusinessResult = new FindBusinessResult();
}

enum BusinessInfoSteps {
    Start, ConfirmGuess, Followup
}

enum CurrentScreen {
    Loading, MainForm
}

class HelperData {
    ownerSsn: string = '';
}

class BusinessInfoFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    businessInfo: BusinessInfo = new BusinessInfo();
    firstGuess: BusinessInfoFirstGuess = new BusinessInfoFirstGuess();
    currentScreen: number = CurrentScreen.Loading;
    helperData: HelperData = new HelperData();
}

class BusinessInfoForm extends React.Component<BusinessInfoFormProps, BusinessInfoFormState> {

    protected businessInfo: BusinessInfo = new BusinessInfo([]);
    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: BusinessInfoFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleFirstGuessConfirmClickYes = this.handleFirstGuessConfirmClickYes.bind(this);
        this.handleFirstGuessConfirmClickNo = this.handleFirstGuessConfirmClickNo.bind(this);

        this.state = new BusinessInfoFormState();
    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/business')
            .then((r) => {

                let businessInfo = r.data.data.state.businessInfo;
                let firstGuess = r.data.data.state.firstGuess;
                let helperData = r.data.data.helperData;
                this.refreshFormData(businessInfo, firstGuess, helperData);

            });
    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let businessInfo = _.clone(this.state.businessInfo);

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        businessInfo.setDataByName(inputData);

        this.setState({
            businessInfo: businessInfo
        });
    }

    getCurrentStep(): number {

        let firstGuess = this.state.firstGuess;

        if (!firstGuess.performed) {
            return BusinessInfoSteps.Start;
        }

        if (firstGuess.found && firstGuess.confirmed === GuessConfirmed.Yes) {
            return BusinessInfoSteps.Followup;
        }

        if (firstGuess.found && firstGuess.confirmed === GuessConfirmed.No) {
            return BusinessInfoSteps.Followup;
        }

        if (firstGuess.performed && !firstGuess.found) {
            return BusinessInfoSteps.Followup;
        }

        if (firstGuess.found && firstGuess.confirmed === GuessConfirmed.Unknown) {
            return BusinessInfoSteps.ConfirmGuess;
        }

        return BusinessInfoSteps.Start;

    }

    handleBackClick(): void {

        switch (this.getCurrentStep()) {
            case BusinessInfoSteps.Start:
                this.props.onGoBack();
                break;
            case BusinessInfoSteps.Followup:

                let firstGuess = _.clone(this.state.firstGuess);
                let ef = _.clone(this.state.errorFields);
                ef.clear();
                firstGuess.confirmed = GuessConfirmed.Unknown;
                firstGuess.performed = false;
                this.setState({
                    firstGuess: firstGuess,
                    errorFields: ef
                });

                break;
        }

    }

    refreshFormData(businessInfo: BusinessInfo, firstGuess: BusinessInfoFirstGuess, helperData: HelperData) {

        let inputs = [];
        for (var j in businessInfo.inputs) {
            inputs.push(businessInfo.inputs[j]);
        }

        this.setState({
            businessInfo: new BusinessInfo(inputs),
            firstGuess: firstGuess,
            currentScreen: CurrentScreen.MainForm,
            helperData: helperData
        });
    }

    storeBusinessInfo(data: BusinessInfoFormState): Promise<HttpClientResponse> {
        return httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/business', data);
    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        if (!this.validateFields()) {
            return;
        }

        switch (this.getCurrentStep()) {

            case BusinessInfoSteps.Start:

                openLoader(true);

                this.storeBusinessInfo(this.state)
                    .then((r) => {
                        let businessInfo = r.data.data.state.businessInfo;
                        let firstGuess = r.data.data.state.firstGuess;
                        let helperData = r.data.data.helperData;
                        this.refreshFormData(businessInfo, firstGuess, helperData);
                    })
                    .catch((r) => {

                    }).finally(() => {
                        openLoader(false);
                    })

                break;
            case BusinessInfoSteps.Followup:

                openLoader(true);

                this.storeBusinessInfo(this.state)
                    .then((r) => {
                        let businessInfo = r.data.data.state.businessInfo;
                        let firstGuess = r.data.data.state.firstGuess;
                        let helperData = r.data.data.helperData;
                        this.refreshFormData(businessInfo, firstGuess, helperData);
                        this.props.onCompleteForm();
                    })
                    .catch((r) => {

                    })
                    .finally(() => {
                        openLoader(false);
                    })


                break;

        }

    }


    getRequiredFields(): string[] {

        let required: string[] = [];

        switch (this.getCurrentStep()) {

            case BusinessInfoSteps.Start:

                required.push(BusinessInputFormNames.entityDba);
                required.push(BusinessInputFormNames.zip);

                break;

            case BusinessInfoSteps.Followup:

                for (let name in FORM_PROPERTY_LIST) {
                    let v = FORM_PROPERTY_LIST[name];
                    if (v.required) {
                        required.push(v.name);
                    }
                }

                break;

        }

        return required;
    }

    validateRequired(): boolean {

        let required = this.getRequiredFields();


        for (var i in required) {

            if (!this.state.businessInfo.getDataByName(required[i]) || this.state.businessInfo.getDataByName(required[i]).value === '') {
                this.errorFields.set(required[i], new ErrorInfo(true));
            }
        }

        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }


    validateFields(): boolean {

        this.errorFields = _.clone(this.state.errorFields);

        this.validateRequired();

        switch (this.getCurrentStep()) {
            case BusinessInfoSteps.Start:

                if (!validator.isPostalCode(this.state.businessInfo.getDataValueByName(BusinessInputFormNames.zip), "US")) {
                    this.errorFields.set(BusinessInputFormNames.zip, new ErrorInfo(true));
                }
                break;
            case BusinessInfoSteps.Followup:

                let val = (StringUtils.nFormat(this.state.businessInfo.getDataValueByName(BusinessInputFormNames.amountRequested)));
                if (val > 9999999999) {
                    this.errorFields.set(BusinessInputFormNames.amountRequested, new ErrorInfo(true));
                }

                let val2 = (StringUtils.nFormat(this.state.businessInfo.getDataValueByName(BusinessInputFormNames.avgMonthlyRevenue)));
                if (val2 > 9999999999) {
                    this.errorFields.set(BusinessInputFormNames.avgMonthlyRevenue, new ErrorInfo(true));
                }

                let val3 = (StringUtils.nFormat(this.state.businessInfo.getDataValueByName(BusinessInputFormNames.avgDailyBankBalance)));
                if (val3 > 9999999999) {
                    this.errorFields.set(BusinessInputFormNames.avgDailyBankBalance, new ErrorInfo(true));
                }

                let val4 = (StringUtils.nFormat(this.state.businessInfo.getDataValueByName(BusinessInputFormNames.openMcaBalance)));
                if (val4 > 9999999999) {
                    this.errorFields.set(BusinessInputFormNames.openMcaBalance, new ErrorInfo(true));
                }

                let val5 = this.state.businessInfo.getDataValueByName(BusinessInputFormNames.startDate);
                const parsedDate = parse('01/'+val5, 'P', new Date(), {
                    locale: enUS
                });
                const isValidDate = isValid(parsedDate);
                if(!isValidDate) {
                    this.errorFields.set(BusinessInputFormNames.startDate, new ErrorInfo(true));
                } else {
                    if(parsedDate.getTime() > new Date().getTime()){
                        this.errorFields.set(BusinessInputFormNames.startDate, new ErrorInfo(true));
                    }
                }

                // let val6 = this.state.businessInfo.getDataValueByName(BusinessInputFormNames.stateOfIncorporation);

                // if(val6 && typeof stateKV[val6.trim().toUpperCase()] === 'undefined') {
                //     this.errorFields.set(BusinessInputFormNames.stateOfIncorporation, new ErrorInfo(true));
                // }

                break;
        }


        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }


    getMainFormRender() {
        switch (this.getCurrentStep()) {
            case BusinessInfoSteps.Start:
                return this.getStartJSX();
            case BusinessInfoSteps.ConfirmGuess:
                return this.getConfirmInfoJSX();
            case BusinessInfoSteps.Followup:
                return this.getFollowupJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }
    }

    render() {

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                return <LinearProgress></LinearProgress>;
            case CurrentScreen.MainForm:
                return this.getMainFormRender();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }

    }

    getStartJSX(): JSX.Element {
        return <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} >
                    <BasicInput
                        inputProps={FORM_PROPERTY_LIST[BusinessInputFormNames.entityDba]}
                        error={this.state.errorFields.get(BusinessInputFormNames.entityDba)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.state.businessInfo.getDataByName(BusinessInputFormNames.entityDba)?.value}
                        formOrder={1}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <BasicInput
                        inputProps={FORM_PROPERTY_LIST[BusinessInputFormNames.zip]}
                        error={this.state.errorFields.get(BusinessInputFormNames.zip)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.state.businessInfo.getDataByName(BusinessInputFormNames.zip)?.value}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                        onClick={this.handleBackClick} fullWidth>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} >
                    <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                        onClick={this.handleContinueClick} fullWidth>
                        Continue
                    </Button>
                </Grid>

            </Grid>
        </React.Fragment>;
    }

    private getInputData(name: string, value: string): InputData {
        return InputData.newObj(name, value, FORM_PROPERTY_LIST[name].label);
    }

    getFoundAsInputData(firstGuess: BusinessInfoFirstGuess): {
        [s: string]: InputData
    } {

        let ret: {
            [s: string]: InputData
        } = {};

        ret[BusinessInputFormNames.entityDba] = this.getInputData(BusinessInputFormNames.entityDba, firstGuess.resultInfo.businessName);
        ret[BusinessInputFormNames.zip] = this.getInputData(BusinessInputFormNames.zip, firstGuess.resultInfo.zip);
        let addressStr = [];
        if (firstGuess.resultInfo.streetLine1 !== null && firstGuess.resultInfo.streetLine1.trim() !== '') {
            addressStr.push(firstGuess.resultInfo.streetLine1);
        }
        if (firstGuess.resultInfo.streetLine2 !== null && firstGuess.resultInfo.streetLine2.trim() !== '') {
            addressStr.push(firstGuess.resultInfo.streetLine2);
        }
        ret[BusinessInputFormNames.address] = this.getInputData(BusinessInputFormNames.address, addressStr.join(", "));
        ret[BusinessInputFormNames.phone] = this.getInputData(BusinessInputFormNames.phone, firstGuess.resultInfo.phone);

        return ret;
    }

    handleFirstGuessConfirmClickYes() {

        let businessInfo = _.clone(this.state.businessInfo);
        let firstGuess = _.clone(this.state.firstGuess);

        firstGuess.confirmed = GuessConfirmed.Yes;

        let inputDatas = this.getFoundAsInputData(firstGuess);

        for (var i in inputDatas) {
            let inputData = inputDatas[i];
            businessInfo.setDataByName(inputData);
        }

        openLoader(true);

        this.storeBusinessInfo({
            businessInfo: businessInfo,
            firstGuess: firstGuess,
            currentScreen: this.state.currentScreen,
            helperData: this.state.helperData,
            errorFields: this.state.errorFields
        }).then((r) => {
            let businessInfo = r.data.data.state.businessInfo;
            let firstGuess = r.data.data.state.firstGuess;
            let helperData = r.data.data.helperData;
            this.refreshFormData(businessInfo, firstGuess, helperData);
        }).finally(() => {
            openLoader(false);
        });

    }

    clearFoundAsInputData(): {
        [s: string]: InputData
    } {

        let ret: {
            [s: string]: InputData
        } = {};

        ret[BusinessInputFormNames.address] = this.getInputData(BusinessInputFormNames.address, '');
        ret[BusinessInputFormNames.phone] = this.getInputData(BusinessInputFormNames.phone, '');

        return ret;
    }

    handleFirstGuessConfirmClickNo() {

        let businessInfo = _.clone(this.state.businessInfo);
        let firstGuess = _.clone(this.state.firstGuess);

        firstGuess.confirmed = GuessConfirmed.No;

        let inputDatas = this.clearFoundAsInputData();

        for (var i in inputDatas) {
            let inputData = inputDatas[i];
            businessInfo.setDataByName(inputData);
        }

        openLoader(true);

        this.storeBusinessInfo({
            businessInfo: businessInfo,
            firstGuess: firstGuess,
            currentScreen: this.state.currentScreen,
            helperData: this.state.helperData,
            errorFields: this.state.errorFields
        }).then((r) => {
            let businessInfo = r.data.data.state.businessInfo;
            let firstGuess = r.data.data.state.firstGuess;
            let helperData = r.data.data.helperData;
            this.refreshFormData(businessInfo, firstGuess, helperData);
        }).finally(() => {
            openLoader(false);
        });
    }

    getConfirmedFields(): string[] {

        let firstGuess = this.state.firstGuess;

        if (firstGuess.confirmed !== GuessConfirmed.Yes) {
            return [];
        }

        let confirmedFields = [];

        if (firstGuess.resultInfo.businessName && !validator.isEmpty(firstGuess.resultInfo.businessName)) {
            confirmedFields.push(BusinessInputFormNames.entityDba);
        }

        if (firstGuess.resultInfo.phone && !validator.isEmpty(firstGuess.resultInfo.phone)) {
            confirmedFields.push(BusinessInputFormNames.phone);
        }

        if ((firstGuess.resultInfo.streetLine1 && !validator.isEmpty(firstGuess.resultInfo.streetLine1))
            || (firstGuess.resultInfo.streetLine2 && !validator.isEmpty(firstGuess.resultInfo.streetLine2))) {

            if (firstGuess.resultInfo.zip && !validator.isEmpty(firstGuess.resultInfo.zip)) {
                confirmedFields.push(BusinessInputFormNames.address);
                confirmedFields.push(BusinessInputFormNames.zip);
            }

        }

        return confirmedFields;
    }

    getConfirmInfoJSX(): JSX.Element {

        let firstGuess = this.state.firstGuess;

        return <React.Fragment>
            <Grid container spacing={2} style={{ textAlign: 'center' }} className={this.props.classes.guessConfirmationContainer}>
                <Grid item sm={9} >
                    <Typography variant="subtitle1" gutterBottom>
                        We've found your business based on the data you provided.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Is the below information correct?
                    </Typography>

                    <div className={this.props.classes.firstGuess}>
                        <Typography variant="body2" gutterBottom>
                            {firstGuess.resultInfo.businessName}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {firstGuess.resultInfo.streetLine1}

                            {firstGuess.resultInfo.streetLine2 && ", " + firstGuess.resultInfo.streetLine2}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {firstGuess.resultInfo.city}, {firstGuess.resultInfo.state} {firstGuess.resultInfo.zip}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {firstGuess.resultInfo.phone}
                        </Typography>
                    </div>

                    <Grid container spacing={2} >
                        <Grid item xs={6} >
                            <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleFirstGuessConfirmClickNo} fullWidth>
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={6} >
                            <Button color="primary" variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleFirstGuessConfirmClickYes} fullWidth>
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </React.Fragment >;
    }

    getFollowupJSX(): JSX.Element {

        let fields = [
            BusinessInputFormNames.amountRequested,
            BusinessInputFormNames.useOfProceeds,
            BusinessInputFormNames.entityDba,
            BusinessInputFormNames.entityName,
            BusinessInputFormNames.taxId,
            BusinessInputFormNames.address,
            BusinessInputFormNames.zip,
            BusinessInputFormNames.phone,
            BusinessInputFormNames.entityType,
            BusinessInputFormNames.stateOfIncorporation,
            BusinessInputFormNames.startDate,
            BusinessInputFormNames.industryType,
            BusinessInputFormNames.ownLeaseProperty,
            BusinessInputFormNames.avgMonthlyRevenue,
            BusinessInputFormNames.avgDailyBankBalance,
            BusinessInputFormNames.openMcaBalance,
            BusinessInputFormNames.website
        ];

        let gridItems: {
            elm: JSX.Element,
            width?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
        }[] = [];
        let o = 0;
        for (var i in fields) {

            let field = fields[i];

            if (this.getConfirmedFields().indexOf(field) >= 0) {
                // continue;
            }
            o++;
            switch (field) {


                case BusinessInputFormNames.entityDba:

                    gridItems.push({
                        elm: <BasicInput
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            formOrder={o}
                        />,
                        width: 12
                    });
                    break;
                case BusinessInputFormNames.entityName:

                    gridItems.push({
                        elm: <CopyRecommendedInput
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            recommendationName='Legal Name is same as D/B/A'
                            recommendationValue={this.state.businessInfo.getDataByName(BusinessInputFormNames.entityDba).value}
                            formOrder={o}>

                        </CopyRecommendedInput>
                    });

                    break;
                case BusinessInputFormNames.taxId:

                    gridItems.push({
                        elm: <CopyRecommendedInputTaxId
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            recommendationName='Tax ID is same as my SSN'
                            recommendationValue={this.state.helperData.ownerSsn}
                            formOrder={o}>
                        </CopyRecommendedInputTaxId>
                    });

                    break;
                case BusinessInputFormNames.entityType:

                    gridItems.push({
                        elm: <OtherSelect
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            options={entityTypeOptionsMerchantForm}>
                        </OtherSelect>
                    });


                    break;
                case BusinessInputFormNames.stateOfIncorporation:

                        gridItems.push({
                            elm: <BasicInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </BasicInput>
                        });


                        break;

                case BusinessInputFormNames.industryType:

                    gridItems.push({
                        elm: <OtherSelect
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            options={industryTypeOptions}>
                        </OtherSelect>
                    });
                    break;

                case BusinessInputFormNames.address:

                    gridItems.push({
                        elm: <SmartyAddressInput
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value || ''}
                            zipCode={this.state.businessInfo.getDataByName(BusinessInputFormNames.zip)?.value}
                        ></SmartyAddressInput>
                    });

                    break;


                case BusinessInputFormNames.ownLeaseProperty:

                    gridItems.push(
                        {
                            elm: <BasicSelect
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}
                                options={propertyOwnershipOptions}>
                            </BasicSelect>
                        }
                    );

                    break;

                case BusinessInputFormNames.amountRequested:
                    gridItems.push(
                        {
                            elm: <CurrencyInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </CurrencyInput>,
                            width: 6
                        }
                    );
                    break;

                case BusinessInputFormNames.useOfProceeds:
                    gridItems.push(
                        {
                            elm: <OtherSelect
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}
                                options={useOfProceedsOptions}>
                            </OtherSelect>,
                            width: 6
                        }
                    );
                    break;
                case BusinessInputFormNames.website:

                        gridItems.push(
                            {
                                elm: <BasicInput
                                    inputProps={FORM_PROPERTY_LIST[field]}
                                    error={this.state.errorFields.get(field)?.error}
                                    onInputChange={this.handleBasicInputChange}
                                    value={this.state.businessInfo.getDataByName(field)?.value || ''}>
                                </BasicInput>,
                                width: 6
                            }
                        );

                        break;
                case BusinessInputFormNames.avgMonthlyRevenue:

                    gridItems.push(
                        {
                            elm: <CurrencyInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </CurrencyInput>
                        }
                    );

                    break;

                case BusinessInputFormNames.avgDailyBankBalance:

                    gridItems.push(
                        {
                            elm: <CurrencyInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </CurrencyInput>
                        }
                    );

                    break;
                case BusinessInputFormNames.openMcaBalance:
/*
                    gridItems.push(
                        {
                            elm: <CurrencyInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value || '0'}>
                            </CurrencyInput>
                        }
                    );
*/

                    gridItems.push(
                        {
                            elm: <OtherSelect
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}
                                options={positionOptions}
                                otherName="Yes"
                                otherValue=""
                                otherType="currency">
                            </OtherSelect>,
                            width: 6
                        }
                    );


                    break;

                case BusinessInputFormNames.phone:

                    gridItems.push(
                        {
                            elm: <PhoneInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </PhoneInput>
                        }
                    );

                    break;

                case BusinessInputFormNames.startDate:
                    gridItems.push(
                        {
                            elm: <YearMonthPicker
                                inputProps={FORM_PROPERTY_LIST[field]}
                                error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.state.businessInfo.getDataByName(field)?.value}>
                            </YearMonthPicker>
                        }
                    );

                    break;
                default:

                    gridItems.push({
                        elm: <BasicInput
                            inputProps={FORM_PROPERTY_LIST[field]}
                            error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.state.businessInfo.getDataByName(field)?.value}
                            formOrder={o}
                        />
                    });
                    break;
            }


        }

        return <React.Fragment>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                        Tell us more about your business
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <Grid container spacing={2}>

                        {gridItems.map((it, index) => {
                            let itemJSX = it.elm;
                            let width = it.width ? it.width : 6;
                            return <Grid key={index} item xs={12} sm={width} >
                                {itemJSX}
                            </Grid>
                        })}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}  >

                            <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleBackClick} fullWidth>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleContinueClick} fullWidth>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(BusinessInfoForm));