import React, { ErrorInfo } from "react";
import { WithStyles, Theme, createStyles, withTheme, withStyles, Card, CardContent, Typography, Button, ButtonGroup, LinearProgress } from "@material-ui/core";

import httpClient from "../../../util/HttpClient";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";

import { CategoryUpload, uploadSections } from "../../../util/DocumentUtils";
import UploadInput, { UploadedFile } from "../../../util/ui/form/UploadInput";
import { FORMAPI_BASE } from "../BaseUtil";
import { ClientAppConfig } from "../../../util/AppUtil";
import { openInviteOwnerForm } from "./owner/InviteOwnerForm";
import OwnerList, { EndFormOwner } from "./owner/OwnerList";



const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    },
    avatarComplete: {
        backgroundColor: theme.palette.success.dark,
    },
    avatarIncomplete: {
        backgroundColor: theme.palette.warning.dark,
    },
    centeredButtonGroup: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        }

    }
});

enum CurrentScreen {
    Loading, MainForm
}

interface EndFormProps extends WithStyles<typeof styles> {
    formToken: string;
    clientAppConfig: ClientAppConfig;
    ownerIndex: number;
    urlToken: string;
    fileToken: string;
}

class EndFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    currentScreen: number = CurrentScreen.Loading;
    uploadedFiles: {
        [category: string]: CategoryUpload
    } = {};
    owners: EndFormOwner[] = [];

    constructor() {
        this.uploadedFiles = {};
        for (var i in uploadSections) {
            this.uploadedFiles[uploadSections[i].category] = new CategoryUpload();
            this.uploadedFiles[uploadSections[i].category].name = uploadSections[i].category;
        }
    }
    section: "owner" | "document" | '' = '';
    totalOwnership: number | null = null;
}

class EndForm extends React.Component<EndFormProps, EndFormState> {

    constructor(props: EndFormProps) {
        super(props);

        this.state = new EndFormState();
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.refreshFiles = this.refreshFiles.bind(this);
        this.handleDocsButtonClick = this.handleDocsButtonClick.bind(this);
        this.handleInviteOwnerClick = this.handleInviteOwnerClick.bind(this);
        this.handleOwnersButtonClick = this.handleOwnersButtonClick.bind(this);
        this.handleOwnerAction = this.handleOwnerAction.bind(this);
        this.deleteOwner = this.deleteOwner.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {

        setTimeout(() => {

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

        }, 100);

        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/endForm')
            .then((r) => {

                this.refreshFiles(r.data.data.files);
                this.setState({
                    totalOwnership: r.data.data.totalOwnership,
                    owners: r.data.data.owners,
                    section: r.data.data.totalOwnership < 51 ? 'owner' : ''
                })

            }).finally(() => {
                openLoader(false);
            });

        this.setState({
            currentScreen: CurrentScreen.MainForm
        })

    }

    handleFileUpload(files: UploadedFile[]): void {

        if (files.length === 0) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/files', files)
            .then((r) => {
                this.refreshFiles(r.data.data);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }

    refreshFiles(data: any): void {

        let files: {
            [category: string]: CategoryUpload
        } = {};

        for (var i in data) {
            let d = data[i];
            let c = CategoryUpload.fromData(d);
            files[d.name] = c;
        }

        this.setState({
            uploadedFiles: files
        });

    }


    handleDocsButtonClick(e: React.MouseEvent | React.TouchEvent): void {
        this.setState({
            section: 'document'
        })
    }

    handleOwnersButtonClick(): void {
        this.setState({
            section: 'owner'
        })
    }

    handleOwnerAction(event: React.MouseEvent): void {

        let dataId = event.currentTarget.getAttribute('data-id');

        if (!dataId) {
            return;
        }

        let ownerIndex = parseInt(dataId);
        let action = event.currentTarget.getAttribute('data-action');

        switch (action) {
            case 'delete':
                this.deleteOwner(ownerIndex);
                break;
        }

    }

    deleteOwner(index: number) {

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/owner/delete')
            .then((r) => {
                this.refreshData();
            })
            .catch((r) => {
            })
            .finally(() => {
                openLoader(false);
            });

    }

    refreshData() {
        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/endForm')
            .then((r) => {

                this.refreshFiles(r.data.data.files);
                this.setState({
                    totalOwnership: r.data.data.totalOwnership,
                    owners: r.data.data.owners
                })

            }).finally(() => {
                openLoader(false);
            });
    }


    handleInviteOwnerClick(): void {
        openInviteOwnerForm(this.refreshData);
    }

    render() {

        if (this.state.totalOwnership === null) {
            return <LinearProgress></LinearProgress>;
        }

        let message = 'The Application has been Successfully Submitted.';
        if (this.state.totalOwnership < 51) {
            message = 'The Application has been Successfully Updated.';
        }

        let repTitle = 'Funding Specialist';
        if (this.props.clientAppConfig.repTitle !== '') {
            repTitle = this.props.clientAppConfig.repTitle
        }

        return <React.Fragment>

            <Card elevation={0}>

                <CardContent>



                    <React.Fragment>
                        <Typography align='center' variant="h5" gutterBottom>{message}</Typography>
                        <Typography align='center' variant='subtitle1' gutterBottom>
                            {this.state.totalOwnership < 51 ?
                                <p>Total Ownership listed on the application is less than 51%.<br />Please invite your co-owner / partner as applicant.</p>
                                :
                                "Your " + repTitle + " " + this.props.clientAppConfig.repName.split(" ")[0] + " will be in touch with you shortly."}
                        </Typography>
                    </React.Fragment>



                    {
                        <div className={this.props.classes.centeredButtonGroup}>
                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                {this.state.totalOwnership < 100 &&
                                    <Button color="primary" onClick={this.handleOwnersButtonClick}>
                                        Owners
                                    </Button>
                                }
                                <Button color="primary" onClick={this.handleDocsButtonClick}>
                                    Documents
                                </Button>

                            </ButtonGroup>
                        </div>

                    }
                    {
                        this.state.section === 'document' &&
                        uploadSections.map((u, i) => {

                            return <UploadInput
                                key={i}
                                category={u.category}
                                label={u.label}
                                required={u.required}
                                fileListUploaded={this.state.uploadedFiles[u.category]?.getFilenames()}
                                error={false}
                                formToken={this.props.fileToken}
                                onFileupload={this.handleFileUpload}
                                urlToken={this.props.urlToken}
                            >

                            </UploadInput>
                        })

                    }
                    {
                        this.state.section === 'owner' && this.state.totalOwnership < 100 &&
                        < div style={{
                            maxWidth: '575px',
                            margin: '0px auto'
                        }}>
                            <OwnerList
                                onInviteOwnerClick={this.handleInviteOwnerClick}
                                onOwnerDelete={this.deleteOwner}
                                ownerIndex={this.props.ownerIndex}
                                owners={this.state.owners}
                            />

                        </div>
                    }

                </CardContent>


            </Card>


        </React.Fragment >
    }
}

export default withTheme(withStyles(styles)(EndForm));