import React from "react";
import { Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, Grid, Typography, CardContent, Card, CardHeader, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import httpClient from "../../../util/HttpClient";

import { openLoader } from "../../../util/ui/dialog/loader/Loader";

import { FormInputPropertiesInterface } from "../../../util/ui/form/FormInputProperties";
import { BasicInput } from "../../../util/ui/form/inputs/BasicInput";
import { AbstractInfo } from "./AbstractInfo";
import SSNInput from "../../../util/ui/form/inputs/SSNInput";
import { Alert, AlertTitle } from "@material-ui/lab";
import { format } from 'date-fns'
import ReviewApplication, { ReviewApplicationInfo } from "./esign/ReviewApplication";
import { CategoryUpload } from "../../../util/DocumentUtils";
import { BusinessInfo } from "./BusinessInfoForm";
import { EachOwnerFormInfo, OwnerInfo } from "./OwnerInfoForm";
import { FORMAPI_BASE } from "../BaseUtil";
import { DeviceInfo } from "../../../util/WindowUtils";
import { PersonAdd } from "@material-ui/icons";
import { openInviteOwnerForm } from "./owner/InviteOwnerForm";
import OwnerList, { EndFormOwner } from "./owner/OwnerList";
import { ClientAppConfig } from "../../../util/AppUtil";
import { businessTitleOptionsMerchantForm } from "../../../util/SelectOptions";
import OtherSelect from "../../../util/ui/form/OtherSelect";

enum EsignFormNames {
    ssn = "ssn",
    zip = "zip",
    address = "address",
    city = "city",
    state = "state",
    fullname = "fullname",
    title = "title",
}

export const ESIGN_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: EsignFormNames.fullname,
    label: 'Full Name',
    required: true,
    width: 9
}, {
    name: EsignFormNames.title,
    label: 'Title',
    required: true,
    width: 3
}];

let FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in ESIGN_FORM_PROPERTY_ARRAY) {
    FORM_PROPERTY_LIST[ESIGN_FORM_PROPERTY_ARRAY[i].name] = ESIGN_FORM_PROPERTY_ARRAY[i];
}

export const ESIGN_FORM_PROPERTY_MAP = FORM_PROPERTY_LIST;


const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});

interface EsignFormProps extends WithStyles<typeof styles> {
    onEditBusiness(): void;
    onEditDocuments(): void;
    onEditOwner(ownerIndex: number): void;
    onCompleteForm(): void;
    formToken: string;
    ownerIndex: number;
    urlToken: string;
    clientAppConfig: ClientAppConfig;
}


enum AddnlInfoSteps {
    Start
}

enum CurrentScreen {
    Loading, MainForm
}

class ApplicantAuthorization extends AbstractInfo {

}

class EsignFormState {
    reviewData: ReviewApplicationInfo = new ReviewApplicationInfo();
    errorFields: Map<string, ErrorInfo> = new Map();
    currentScreen: number = CurrentScreen.Loading;
    authorization: ApplicantAuthorization = new ApplicantAuthorization();
    signDate: string = '';
    signDate2: string = '';
    deviceInfo: DeviceInfo = DeviceInfo.getCurrentDeviceInfo();
    checkedA: boolean = false;
    agreements: {
        [s: number]: boolean
    } = {};
    totalOwnership: number = 0;
    ownerList: EndFormOwner[] = [];
}

interface SignerData {
    agreements: string[];
    signTitle: string;
    signName: string;
    deviceInfo: DeviceInfo;
    ownerIndex: number;
}

class EsignForm extends React.Component<EsignFormProps, EsignFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: EsignFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleAgreementChange = this.handleAgreementChange.bind(this);
        this.onAddOwnerApplicantClick = this.onAddOwnerApplicantClick.bind(this);
        this.handleInviteOwnerClick = this.handleInviteOwnerClick.bind(this);
        this.deleteOwner = this.deleteOwner.bind(this);
        this.refreshData = this.refreshData.bind(this);

        let state = new EsignFormState();
        for (let i in this.props.clientAppConfig.esignConditions) {
            state.agreements[i] = false;
        }
        this.state = state;
    }

    componentDidMount() {

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/esign')
            .then((r) => {

                this.refreshFormData(r.data.data.e, r.data.data.r);

                this.setState({
                    currentScreen: CurrentScreen.MainForm,
                    signDate2: format(new Date(), 'EEEE, LLLL d \'at\' h:mma'),
                    signDate: format(new Date(), 'EEEE, LLLL d h:mma'),
                    totalOwnership: parseFloat(r.data.data.o),
                    ownerList: r.data.data.l
                })
            });


        setTimeout(() => {

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

        }, 100);
    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {
        this.errorFields = _.clone(this.state.errorFields);
        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }
        let a = _.clone(this.state.authorization);
        a.setDataByName(input);
        this.setState({
            authorization: a
        });
    }


    getCurrentStep(): number {

        return AddnlInfoSteps.Start;

    }

    refreshFormData(e: any, r: any) {

        let review = new ReviewApplicationInfo();
        review.bankConnect = r.bankConnect;

        let files: {
            [category: string]: CategoryUpload
        } = {};

        for (var i in r.uploadedFiles) {
            let d = r.uploadedFiles[i];
            let c = CategoryUpload.fromData(d);
            files[d.name] = c;
        }

        review.uploadedFiles = files;
        review.businessInfo = new BusinessInfo(r.businessInfo.state.businessInfo.inputs);
        review.ownerInfo = new EachOwnerFormInfo();
        review.ownerInfo.amountRequested = r.ownerInfo.amountRequested;
        review.ownerInfo.ownerInfo = new OwnerInfo(r.ownerInfo.ownerInfo.inputs);


        this.setState({
            authorization: new ApplicantAuthorization(typeof e !== 'undefined' && e !== null && e.authorization ? e.authorization.inputs : []),
            reviewData: review
        })
    }

    validate(): boolean {
        return this.validateForm();
    }

    validateForm(): boolean {


        let errors = new Map<string, ErrorInfo>();

        for (var i in ESIGN_FORM_PROPERTY_ARRAY) {

            let name = ESIGN_FORM_PROPERTY_ARRAY[i].name;
            let value = this.state.authorization.getDataByName(name)?.value || '';

            if (ESIGN_FORM_PROPERTY_ARRAY[i].required && value === '') {
                errors.set(name, new ErrorInfo(true));
            }

        }

        this.setState({
            errorFields: errors
        });

        return !(errors.size > 0);

    }

    postEsignData(d: SignerData) {

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/esign', d)
            .then((r) => {

                this.props.onCompleteForm();
                // this.refreshFormData(answers);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        if (!this.validate()) {
            return;
        }

        this.postEsignData({
            deviceInfo: DeviceInfo.getCurrentDeviceInfo(),
            ownerIndex: this.props.ownerIndex,
            agreements: this.props.clientAppConfig.esignConditions,
            signName: this.state.authorization.getDataByName(EsignFormNames.fullname).value,
            signTitle: this.state.authorization.getDataByName(EsignFormNames.title).value
        });
    }

    handleAgreementChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let agreements = _.clone(this.state.agreements);
        agreements[parseInt(e.currentTarget.getAttribute('data-agreement-index') || '0')] = e.currentTarget.checked;
        this.setState({
            agreements: agreements
        })
    }

    render() {

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                return <LinearProgress></LinearProgress>;
            case CurrentScreen.MainForm:
                return this.getMainFormJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }

    }

    onAddOwnerApplicantClick() {
        openInviteOwnerForm(this.refreshData);
    }

    deleteOwner(index: number) {

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/owner/delete')
            .then((r) => {
                this.refreshData();
            })
            .catch((r) => {
            })
            .finally(() => {
                openLoader(false);
            });

    }

    refreshData() {
        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/esign')
            .then((r) => {

                this.refreshFormData(r.data.data.e, r.data.data.r);

                this.setState({
                    currentScreen: CurrentScreen.MainForm,
                    signDate2: format(new Date(), 'EEEE, LLLL d \'at\' h:mma'),
                    signDate: format(new Date(), 'EEEE, LLLL d h:mma'),
                    totalOwnership: parseFloat(r.data.data.o),
                    ownerList: r.data.data.l
                })
            }).finally(() => {
                openLoader(false)
            });
    }


    handleInviteOwnerClick(): void {
        openInviteOwnerForm(this.refreshData);
    }

    getMainFormJSX(): JSX.Element {

        let allowedSubmission = true;
        for (var i in this.props.clientAppConfig.esignConditions) {
            if (!this.state.agreements[i]) {
                allowedSubmission = false;
                break;
            }
        }

        return <React.Fragment>
            <Grid container spacing={2}>

                {this.state.totalOwnership < 51 && this.props.ownerIndex === 0
                    &&
                    <React.Fragment>
                        <Grid item xs={12} >
                            <Alert
                                severity='warning'
                            >
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <AlertTitle>
                                            Total Ownership listed on the application is less than 51%.
                                        </AlertTitle>
                                        Please fix your ownership details below or add your co-owner / partner as applicant

                                    </Grid>
                                    <Grid item sm={12}>
                                        <Button color='inherit' size='small' onClick={this.onAddOwnerApplicantClick}>
                                            <PersonAdd></PersonAdd> &nbsp; Add Owner Applicant
                                         </Button>
                                    </Grid>
                                </Grid>

                            </Alert>
                        </Grid>
                        {this.state.ownerList.length > 1 &&
                            <Grid item xs={12} >
                                <OwnerList
                                    owners={this.state.ownerList}
                                    onInviteOwnerClick={this.handleInviteOwnerClick}
                                    onOwnerDelete={this.deleteOwner}
                                    ownerIndex={this.props.ownerIndex}
                                    displayOnly={true}
                                    hideFirstOwner={true}
                                />
                            </Grid>
                        }

                        <Grid item xs={12} >
                            <Alert severity='info'>
                                <AlertTitle>
                                    You can continue with your application below.
                                </AlertTitle>
                                Your invited co-owner / partner will receive a separate instruction to add themselves to this application
                            </Alert>
                        </Grid>
                    </React.Fragment>
                }

                <Grid item xs={12} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReviewApplication
                                onEditBusiness={this.props.onEditBusiness}
                                onEditDocuments={this.props.onEditDocuments}
                                onEditOwner={this.props.onEditOwner}
                                data={this.state.reviewData}
                                formToken={this.props.formToken}
                                ownerIndex={this.props.ownerIndex}
                                urlToken={this.props.urlToken}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant='outlined' >
                                <CardHeader title='Applicant E-Signature*' ></CardHeader>
                                <CardContent>

                                    <Grid container spacing={2}>

                                        {ESIGN_FORM_PROPERTY_ARRAY.map((v, index) => {

                                            let jsx = <React.Fragment></React.Fragment>;

                                            switch (v.name) {
                                                case EsignFormNames.ssn:
                                                    jsx = <SSNInput
                                                        inputProps={v}
                                                        error={this.state.errorFields.get(v.name)?.error}
                                                        onInputChange={this.handleBasicInputChange}
                                                        value={this.state.authorization.getDataByName(v.name)?.value}
                                                    />;
                                                    break;
                                                case EsignFormNames.title:
                                                    jsx= <OtherSelect
                                                        inputProps={v}
                                                        error={this.state.errorFields.get(v.name)?.error}
                                                        onInputChange={this.handleBasicInputChange}
                                                        value={this.state.authorization.getDataByName(v.name)?.value}
                                                        options={businessTitleOptionsMerchantForm}>
                                                    </OtherSelect>;
                                                    break;
                                                default:

                                                    jsx = <BasicInput
                                                        inputProps={v}
                                                        error={this.state.errorFields.get(v.name)?.error}
                                                        onInputChange={this.handleBasicInputChange}
                                                        value={this.state.authorization.getDataByName(v.name)?.value}
                                                    />;
                                                    break;
                                            }


                                            return <React.Fragment key={index} >

                                                <Grid item sm={v.width || 12} xs={12}>
                                                    {jsx}
                                                </Grid>
                                                {
                                                    index === ESIGN_FORM_PROPERTY_ARRAY.length - 1 &&
                                                    <Grid item xs={12}>
                                                        <React.Fragment>

                                                            <Alert variant='filled' >
                                                                {this.state.signDate2}
                                                            </Alert>
                                                            <br />

                                                            {
                                                                this.props.clientAppConfig.esignConditions.map((s, i) => {
                                                                    return <React.Fragment key={i}>
                                                                        <FormGroup row>
                                                                            <Typography variant='body2'>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            inputProps={{
                                                                                                'data-agreement-index': i
                                                                                            } as any}
                                                                                            checked={this.state.agreements[i]}
                                                                                            onChange={this.handleAgreementChange}
                                                                                            name={'agreement-' + i}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    label={s}
                                                                                />

                                                                            </Typography>
                                                                        </FormGroup>
                                                                    </React.Fragment>;
                                                                })
                                                            }



                                                        </React.Fragment>
                                                    </Grid>

                                                }
                                            </React.Fragment>
                                        })}

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleContinueClick} disabled={!allowedSubmission} fullWidth>
                                Sign And Submit
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid >
        </React.Fragment >;
    }
}

export default withTheme(withStyles(styles)(EsignForm));