export class Option {
    name: string = '';
    value: string = '';

    static generateSimple(simple: string): Option {
        let o = new Option();
        o.name = simple;
        o.value = simple;
        return o;
    }

    static generateSimpleFromArray(arr: string[]): Option[] {
        let options: Option[] = [];
        for (var i in arr) {
            options.push(Option.generateSimple(arr[i]));
        }
        return options;
    }

    static generateKV(obj: {
        [name: string]: string
    }) {
        let options: Option[] = [];
        for (var i in obj) {
            let o = new Option();
            o.name = obj[i];
            o.value = i;
            options.push(o);
        }
        return options;
    }
}


export const entityTypeOptionsPPP: Option[] = Option.generateSimpleFromArray(["Sole proprietor", "Partnership", "C-Corp", "S-S-Corp", "LLC",
    "Independent contractor", "Eligible self-employed individual",
    "501(c)(3) nonprofit", "501(c)(19) veterans organization", "Tribal business (sec. 31(b)(2)(C) of Small Business Act)", "Other"]);


export const entityTypeOptionsMerchantForm: Option[] = Option.generateSimpleFromArray([
    'S-Corp',
    'C-Corp',
    'Limited Liability Company',
    'Limited Partnership',
    'Limited Liability Partnership',
    'Sole Proprietorship'
]);

export const stateKV : {
    [stateCode: string] : string
} = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};
export const stateList: Option[] = Option.generateKV(stateKV);

export const industryTypeOptions: Option[] = Option.generateSimpleFromArray([
    'Agriculture',
    'Automotive Repair',
    'Automotive Sales & Gas Service Stations',
    'Business Services',
    'Communications',
    'Construction & Contractors',
    'Eating & Drinking Places',
    'Educational Services',
    'Entertainment Services',
    'Faith Based Organization',
    'Finance',
    'Food & Beverage Stores',
    'Freight',
    'Health Services',
    'Hotels & Lodging',
    'Insurance',
    'Legal Services',
    'Manufacturing',
    'Mining & Natural Resource Extraction',
    'Miscellaneous Services',
    'Personal Services',
    'Real Estate',
    'Retail',
    'Storage',
    'Transportation',
    'Wholesale'
]);

export const propertyOwnershipOptions: Option[] = Option.generateSimpleFromArray([
    'Own',
    'Lease'
]);

export const businessTitleOptionsMerchantForm: Option[] = Option.generateSimpleFromArray([
    'Owner',
    'CEO',
    'President'
]);


export const businessTitleOptionsPPP: Option[] = Option.generateSimpleFromArray([
    'Owner',
    'Manager',
    'Accountant',
    'CEO',
    'CFO',
    'COO'
]);

export const purposeSelectOptions: Option[] = Option.generateSimpleFromArray([
    'Payroll',
    'Lease / Mortgage Interest',
    'Utilities'
]);

export const useOfProceedsOptions: Option[] = Option.generateSimpleFromArray([
    'Business Working Capital',
    'Business Expansion',
    'Purchase Inventory',
    'Purchase Equipment',
    'Project Financing',
    'Marketing',
    'Debt Consolidation',
    'Payroll'
]);

export const positionOptions: Option[] = Option.generateKV({
    0 : "No other open Loans/MCA"
});