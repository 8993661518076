import React from "react";
import { Typography } from "@material-ui/core";
import { UploadedFile } from "./ui/form/UploadInput";


export enum FileUploadCategoryNames {
    bankStatement = 'Bank Statement',
    idVerification = 'ID Verification',
    other = 'Other Documents',
    payrollReport = 'Payroll Report',
    employerFiles = 'Employer',
    contractorFiles = 'Contractor',
    selfEmployedFiles = 'Self Employed'
}


export interface UploadSection {
    category: string;
    required: boolean;
    label: JSX.Element;
}

export const uploadSections: UploadSection[] = [{
    category: FileUploadCategoryNames.bankStatement,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>Bank Statements:</strong></Typography>
        <ul>
            <li>Last 3 Months</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.idVerification,
    required: false,
    label: <React.Fragment>
        <Typography gutterBottom><strong>ID Verification</strong></Typography>
        <ul>
            <li>Driver's License (front and back)</li>
            <li>Any other government issued identification</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.other,
    required: false,
    label: <React.Fragment>
        <Typography gutterBottom><strong>Other</strong></Typography>
        <ul>
            <li>Any other documents</li>
        </ul>
    </React.Fragment>
}];


export const uploadSectionsPPP: UploadSection[] = [{
    category: FileUploadCategoryNames.payrollReport,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>Payroll Report (Required):</strong></Typography>
        <ul>
            <li>Payroll Register for the full 2019 year</li>
            <li>Payroll Register all available months in 2020</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.idVerification,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>ID Verification (Required):</strong></Typography>
        <ul>
            <li>Driver's License (front and back)</li>
            <li>Any other government issued identification</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.employerFiles,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>If you have employees:</strong></Typography>
        <ul>
            <li>All 2019 IRS Form 941s or 2019 IRS Form 944</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.contractorFiles,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>If you are an independent contractor:</strong></Typography>
        <ul>
            <li>1099-MISC</li>
        </ul>
    </React.Fragment>
}, {
    category: FileUploadCategoryNames.selfEmployedFiles,
    required: true,
    label: <React.Fragment>
        <Typography gutterBottom><strong>If you are self employed:</strong></Typography>
        <ul>
            <li>2019 IRS 1040 Schedule C</li>
            <li>1099-MISC for each self employed owner</li>
        </ul>
    </React.Fragment>
}];




export class CategoryUpload {
    name: string = '';
    files: UploadedFile[] = [];

    static fromData(data: {
        name: string,
        files: UploadedFile[]
    }): CategoryUpload {
        let o = new CategoryUpload();
        o.name = data.name;
        o.files = data.files;
        return o;
    }

    getFilenames(): string[] {
        let files = [];
        for (var i in this.files) {
            files.push(this.files[i].filename);
        }

        return files;
    }
}