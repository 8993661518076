export class InputData {
    name: string = '';
    value: string = '';
    label: string = '';

    static newObj(name: string, value: string, label: string): InputData {

        let data = new InputData();
        data.name = name;
        data.value = value;
        data.label = label;

        return data;
    }
}