import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import { ListItemAvatar, Tooltip, Avatar, ListItemSecondaryAction, IconButton } from '@material-ui/core';

import { HourglassEmpty, Done, Delete } from '@material-ui/icons';

export interface EndFormOwner {
    ownerIndex: number;
    ownership: number | null;
    email: string;
    status: string;
}


export default function OwnerList(props: {
    owners: EndFormOwner[],
    ownerIndex: number,
    onOwnerDelete: (index: number) => void,
    onInviteOwnerClick: () => void,
    displayOnly?: boolean
    hideFirstOwner?: boolean
}) {
    let theme = useTheme();

    const deleteOwner = (index: number) => {

        props.onOwnerDelete(index);

    }

    const handleOwnerAction = (event: React.MouseEvent) => {

        let dataId = event.currentTarget.getAttribute('data-id');

        if (!dataId) {
            return;
        }

        let ownerIndex = parseInt(dataId);
        let action = event.currentTarget.getAttribute('data-action');

        switch (action) {
            case 'delete':
                deleteOwner(ownerIndex);
                break;
        }

    }

    const handleInviteOwnerClick = () => {
        props.onInviteOwnerClick();
    }

    return (
        <React.Fragment>
            <List>
                {props.owners.map((o, i) => {
                    if (o.ownerIndex === 0 && props.hideFirstOwner) {
                        return <React.Fragment key={i}></React.Fragment>;
                    }
                    let ownership = o.ownership === null || o.ownership > 0 ? o.ownership + '%' : 'NA';

                    return <React.Fragment key={i}>
                        <ListItem  >
                            <ListItemAvatar>
                                <Tooltip title={o.status}>
                                    <Avatar
                                        style={o.status === 'Completed' ?
                                            {
                                                backgroundColor: theme.palette.success.dark
                                            } : {
                                                backgroundColor: theme.palette.warning.dark
                                            }}
                                    >
                                        {o.status !== 'Completed' ?
                                            <HourglassEmpty />
                                            :
                                            <Done />
                                        }

                                    </Avatar>
                                </Tooltip>

                            </ListItemAvatar>
                            <ListItemText
                                primary={o.email}
                                primaryTypographyProps={{
                                    style: {
                                        fontSize: '14px'
                                    }
                                }}
                                secondary={"Ownership: " + ownership}
                                secondaryTypographyProps={{
                                    style: {
                                        fontSize: '14px'
                                    }
                                }}>
                            </ListItemText>

                            {props.ownerIndex === 0 && o.ownerIndex > 0
                                && o.status !== 'Completed'
                                &&
                                <ListItemSecondaryAction>
                                    <IconButton data-action="delete" onClick={handleOwnerAction} data-id={o.ownerIndex} edge="end" aria-label="delete" >
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            }

                        </ListItem>
                        {i !== (props.owners.length - 1) && <Divider />}
                    </React.Fragment>
                })}

            </List>
            {props.displayOnly ? <React.Fragment></React.Fragment>
                :
                <Button fullWidth color='primary' variant='contained' size='small' onClick={handleInviteOwnerClick}>
                    Invite Owner / New Applicant
                                </Button>
            }

        </React.Fragment>
    );

}
