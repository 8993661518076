import React from "react";
import { TextField, InputLabelProps, CircularProgress } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../FormInputProperties";
import { debounce } from "lodash";
import { AutocompleteChangeReason, Autocomplete } from "@material-ui/lab";
import httpClient from "../../../HttpClient";
import validator from "validator";
import BasicInput from "./BasicInput";
import { API_BASE } from "../../../AppUtil";

interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    zipCode: string;
    onInputChange(inputInfo: InputInfo, event?: React.ChangeEvent<HTMLInputElement>): void;
}

class BasicInputState {
    value: string = '';
    open: boolean = false;
    options: string[] = [];
    query: string = '';
    loading: boolean = false;
}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

class SmartyAddressInput extends React.Component<BasicInputProps, BasicInputState> {

    protected inputInfo: InputInfo = new InputInfo();
    private loading: boolean = false;
    private selectedValue: string | null = null;
    loadSuggestionsThrottled: () => void;

    constructor(props: BasicInputProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
        this.setFinalValue = this.setFinalValue.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.loadSuggestionsThrottled = debounce(this.loadSuggestions, 200);
        let state = new BasicInputState();
        state.value = props.value || '';
        this.state = state;
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {

        let i = new InputInfo();
        i.name = event.currentTarget.name;
        i.value = event.currentTarget.value;
        i.label = this.props.inputProps.label;
        this.props.onInputChange(i, event);

    };

    setOpen(open: boolean) {
        this.setState({
            open: open
        });
    }

    componentDidUpdate(prevProps: BasicInputProps) {

        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value || ''
            })
        }

    }

    private _ismounted = false;
    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    onAutocompleteChange(event: React.ChangeEvent<{}>, value: string | null, reason: AutocompleteChangeReason) {
        if (!value) {
            value = '';
        }

        this.selectedValue = value;
        this.setFinalValue(this.selectedValue || '');
    }

    setFinalValue(value: string) {

        let i = new InputInfo();
        i.name = this.props.inputProps.name;
        i.value = value;
        i.label = this.props.inputProps.label;
        this.props.onInputChange(i);
    }

    loadSuggestions() {
        this.setState({
            loading: true,
            open: true
        });

        httpClient.get(API_BASE + '/util/fetchAddressSuggestionsWithZip?zip=' + this.props.zipCode + '&address=' + this.state.query).then((r) => {
            if (!this._ismounted) {
                return;
            }
            if (r.data && typeof r.data.length !== 'undefined' && Array.isArray(r.data) && r.data.length > 0) {
                this.setState({
                    options: r.data
                });
            } else {
                this.setState({
                    options: [],
                    open: false,
                    loading: false
                });
            }
        })
    }

    handleQueryChange(query: string) {

        if (query.length <= 2) {
            return;
        }

        this.setState({
            query: query
        });

        this.setFinalValue(query);

        this.loadSuggestionsThrottled();

    }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        if (this.props.zipCode === '' || !this.props.zipCode || (this.props.zipCode && !validator.isPostalCode(this.props.zipCode, 'US'))) {

            return <BasicInput
                inputProps={this.props.inputProps}
                error={this.props.error}
                onInputChange={this.props.onInputChange}
                value={this.props.value}
            >
            </BasicInput>

        }

        return <Autocomplete
            open={this.state.open}
            onClose={() => {
                if (!this.selectedValue) {
                    this.setFinalValue(this.state.query);
                }
                this.setOpen(false);
            }}
            onChange={this.onAutocompleteChange}
            getOptionSelected={(option: string, value: string) => option === value}
            getOptionLabel={option => option}
            options={this.state.options}
            loading={this.state.loading}
            value={this.props.value}
            freeSolo={true}
            renderInput={params => {

                let handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {

                    this.handleQueryChange(e.currentTarget.value);
                };

                return (
                    <TextField
                        {...params}
                        error={this.props.error}
                        label={this.props.inputProps.label}
                        variant="outlined"
                        onChange={handleTextFieldChange}
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {this.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )
            }}
        />;

    }
}

export default SmartyAddressInput;