import React from "react";
import { TextField, InputLabelProps } from "@material-ui/core";
import { FormInputPropertiesInterface } from "./FormInputProperties";

export interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    textFieldVariant?: "filled" | "outlined" | "standard" | undefined;
    formOrder?: number;
    onInputChange(inputInfo: InputInfo, event?: React.ChangeEvent<HTMLInputElement>): void;
}

export interface BasicInputState {

}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

export abstract class AbstractBasicInput<P extends BasicInputProps, S extends BasicInputState> extends React.Component<P, S> {

    protected inputInfo: InputInfo = new InputInfo();

    constructor(props: P) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.cleanInputBeforeSendingOut = this.cleanInputBeforeSendingOut.bind(this);
    }

    cleanInputBeforeSendingOut(val: string) {
        return val;
    }

    formatValueBeforeDisplaying(val: any) {
        return val;
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.inputInfo.name = event.currentTarget.name;
        this.inputInfo.value = this.cleanInputBeforeSendingOut(event.currentTarget.value);
        this.inputInfo.label = this.props.inputProps.label;
        this.props.onInputChange(this.inputInfo, event);
    };

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;
        let val: string = this.formatValueBeforeDisplaying(this.props.value || '');

        let variant: "filled" | "outlined" | "standard" | undefined = "outlined";
        if (typeof this.props.textFieldVariant !== 'undefined' && this.props.textFieldVariant !== null) {
            variant = this.props.textFieldVariant;
        }

        return <React.Fragment>

            <TextField
                name={this.props.inputProps.name}
                label={this.props.inputProps.label}
                fullWidth
                error={this.props.error ? true : false}
                type={this.props.inputProps.type ? this.props.inputProps.type : 'text'}
                onChange={this.handleChange}
                required={this.props.inputProps.required}
                placeholder={this.props.inputProps.placeholder}
                variant={variant}
                value={val}
                InputLabelProps={inputLabelProps}
                InputProps={{ inputProps: this.props.inputProps, inputMode: this.props.inputProps.inputMode }}
                autoFocus={typeof this.props.formOrder !== 'undefined' ? this.props.formOrder === 1 ? true : false : false}
            />

        </React.Fragment>;
    }
}

export default AbstractBasicInput;