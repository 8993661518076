import React from "react";
import { Grid, Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, CardContent, Card, CardHeader } from "@material-ui/core";
import { InputData } from "../../../util/ui/form/InputData";
import { AbstractInfo } from "./AbstractInfo";
import { FindBusinessResult } from "./business/FindBusinessResult";
import { FormInputPropertiesInterface } from "../../../util/ui/form/FormInputProperties";
import BasicInput from "../../../util/ui/form/inputs/BasicInput";
import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import validator from "validator";
import httpClient from "../../../util/HttpClient";

import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { entityTypeOptionsPPP } from "../../../util/SelectOptions";
import BasicSelect from "../../../util/ui/form/BasicSelect";

import PhoneInput from "../../../util/ui/form/inputs/PhoneInput";

import { CategoryUpload, uploadSections } from "../../../util/DocumentUtils";
import { FORMAPI_BASE } from "../BaseUtils";
import { UploadedFile } from "../../../util/ui/form/UploadInput";

enum BusinessInputFormNames {
    entityName = "entityName",
    entityDba = "entityDba",
    taxId = "taxId",
    entityType = "entityType",

    zip = "zip",
    address = "address",
    phone = "phone",
    email = "email",

}

export const FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: BusinessInputFormNames.entityName,
    label: 'Business Legal Name',
    required: true,
    type: 'text',
    width: 12,
}, {
    name: BusinessInputFormNames.entityDba,
    label: 'Trade Name',
    required: true,
    type: 'text',
    width: 12
}, {
    name: BusinessInputFormNames.taxId,
    label: 'EIN/SSN',
    required: true,
    type: 'text',
    width: 6
}, {
    name: BusinessInputFormNames.entityType,
    label: 'Organization Type',
    required: true,
    width: 6
}, {
    name: BusinessInputFormNames.address,
    label: 'Primary Business Address (Cannot be P.O. Box)',
    required: true,
    width: 6
}, {
    name: BusinessInputFormNames.zip,
    label: 'Zip',
    required: true,
    width: 3
}, {
    name: BusinessInputFormNames.phone,
    label: 'Business Phone',
    required: true,
    width: 3
}, {
    name: BusinessInputFormNames.email,
    label: 'Business Email',
    required: true,
    width: 12
}];

let FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in FORM_PROPERTY_ARRAY) {
    FORM_PROPERTY_LIST[FORM_PROPERTY_ARRAY[i].name] = FORM_PROPERTY_ARRAY[i];
}

export const FORM_PROPERTY_MAP = FORM_PROPERTY_LIST;

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});


export class BusinessInfo extends AbstractInfo {

}

interface BusinessInfoFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onGoBack(): void;

    formToken: string;
}

export class BusinessInfoFirstGuess {
    performed: boolean = false;
    found: boolean = false;
    confirmed: number = 0;
    resultInfo: FindBusinessResult = new FindBusinessResult();
}

enum BusinessInfoSteps {
    Start
}

enum CurrentScreen {
    Loading, MainForm
}



class BusinessInfoFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    businessInfo: BusinessInfo = new BusinessInfo();
    currentScreen: number = CurrentScreen.Loading;
    uploadedFiles: {
        [category: string]: CategoryUpload
    } = {};
    fileUploadErrors: Map<string, boolean> = new Map();

    constructor() {
        this.uploadedFiles = {};
        for (var i in uploadSections) {
            this.uploadedFiles[uploadSections[i].category] = new CategoryUpload();
            this.uploadedFiles[uploadSections[i].category].name = uploadSections[i].category;
            this.fileUploadErrors.set(uploadSections[i].category, false);
        }
    }
}

class BusinessInfoForm extends React.Component<BusinessInfoFormProps, BusinessInfoFormState> {

    protected businessInfo: BusinessInfo = new BusinessInfo([]);
    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: BusinessInfoFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);

        this.state = new BusinessInfoFormState();
    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });


        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/business')
            .then((r) => {

                let businessInfo = r.data.data.b.state.businessInfo;
                let files = r.data.data.f;
                this.refreshFiles(files);
                this.refreshFormData(businessInfo);

            });

    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let businessInfo = _.clone(this.state.businessInfo);

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        businessInfo.setDataByName(inputData);

        this.setState({
            businessInfo: businessInfo
        });
    }

    getCurrentStep(): number {

        return BusinessInfoSteps.Start;

    }

    refreshFormData(businessInfo: BusinessInfo) {

        let inputs = [];
        for (var j in businessInfo.inputs) {
            inputs.push(businessInfo.inputs[j]);
        }

        this.setState({
            businessInfo: new BusinessInfo(inputs),
            currentScreen: CurrentScreen.MainForm
        });
    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        if (!this.validateFields()) {
            return;
        }

        switch (this.getCurrentStep()) {

            case BusinessInfoSteps.Start:

                openLoader(true);

                httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
                    + '/business', this.state)
                    .then((r) => {
                        let businessInfo = r.data.data.state.businessInfo;
                        this.refreshFormData(businessInfo);
                        this.props.onCompleteForm();
                    })
                    .catch((r) => {

                    }).finally(() => {
                        openLoader(false);
                    })

                break;


        }

    }

    handleBackClick(event: React.TouchEvent | React.MouseEvent): void {

        this.props.onGoBack();

    }


    getRequiredFields(): string[] {

        let required: string[] = [];

        for (var i in FORM_PROPERTY_ARRAY) {
            if (FORM_PROPERTY_ARRAY[i].required) {
                required.push(FORM_PROPERTY_ARRAY[i].name);
            }
        }

        return required;
    }

    validateRequired(): boolean {

        let required = this.getRequiredFields();

        for (var i in required) {

            if (!this.state.businessInfo.getDataByName(required[i]) || this.state.businessInfo.getDataByName(required[i]).value === '') {
                this.errorFields.set(required[i], new ErrorInfo(true));
            }
        }

        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }


    validateFields(): boolean {

        if (!this.validateRequired()) {
            return false;
        }


        switch (this.getCurrentStep()) {
            case BusinessInfoSteps.Start:

                if (!validator.isPostalCode(this.state.businessInfo.getDataByName(BusinessInputFormNames.zip).value, "US")) {
                    this.errorFields.set(BusinessInputFormNames.zip, new ErrorInfo(true));
                }
                if (!validator.isMobilePhone(this.state.businessInfo.getDataByName(BusinessInputFormNames.phone).value, "en-US")) {
                    this.errorFields.set(BusinessInputFormNames.phone, new ErrorInfo(true));
                }
                if (!validator.isEmail(this.state.businessInfo.getDataByName(BusinessInputFormNames.email).value)) {
                    this.errorFields.set(BusinessInputFormNames.email, new ErrorInfo(true));
                }
                if (!validator.isLength(this.state.businessInfo.getDataByName(BusinessInputFormNames.taxId).value, {
                    min: 9,
                    max: 9
                })) {
                    this.errorFields.set(BusinessInputFormNames.taxId, new ErrorInfo(true));
                }
                break;
        }


        this.setState({
            errorFields: this.errorFields
        });

        if (this.errorFields.size > 0) {
            return false;
        }

        return true;
    }

    render() {

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                return <LinearProgress></LinearProgress>;
            case CurrentScreen.MainForm:
                return this.getMainFormJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }

    }

    refreshFiles(data: any): void {

        let files: {
            [category: string]: CategoryUpload
        } = {};

        for (var i in data) {
            let d = data[i];
            let c = CategoryUpload.fromData(d);
            files[d.name] = c;
        }

        this.setState({
            uploadedFiles: files
        });

    }

    handleFileUpload(files: UploadedFile[]): void {

        if (files.length === 0) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/files', files)
            .then((r) => {
                this.refreshFiles(r.data.data);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }


    getMainFormJSX(): JSX.Element {

        return <React.Fragment>
            <Card elevation={0}>
                <CardHeader title="Business Information"></CardHeader>
                <CardContent style={{
                    paddingBottom: '0'
                }}>
                    <Grid container spacing={2}>

                        {FORM_PROPERTY_ARRAY.map((v, index) => {
                            let jsx = null;

                            switch (v.name) {

                                case BusinessInputFormNames.entityType:

                                    jsx = <BasicSelect
                                        inputProps={v}
                                        error={this.state.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.state.businessInfo.getDataByName(v.name)?.value}
                                        options={entityTypeOptionsPPP}>
                                    </BasicSelect>;

                                    break;


                                case BusinessInputFormNames.phone:

                                    jsx = <PhoneInput
                                        inputProps={v}
                                        error={this.state.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.state.businessInfo.getDataByName(v.name)?.value}>
                                    </PhoneInput>;
                                    break;

                                default:

                                    jsx = <BasicInput
                                        inputProps={v}
                                        error={this.state.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.state.businessInfo.getDataByName(v.name)?.value}
                                    />;
                                    break;
                            }


                            return <Grid key={index} item xs={12} sm={v.width || 12}>
                                {jsx}
                            </Grid>

                        })}
                    </Grid>
                </CardContent>
            </Card>

            <Card elevation={0} style={{ paddingBottom: '0' }}>
                <CardContent style={{ paddingBottom: '0' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleBackClick} fullWidth>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleContinueClick} fullWidth>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(BusinessInfoForm));